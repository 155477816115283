.ew-Modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  z-index: 1;
}

.ew-Modal.active {
  opacity: 1;
  pointer-events: all;
}

.ew-Modal-content {
  padding: 30px;
  text-align: justify;
  text-justify: inter-word;
  width: 20%;
  min-width: 300px;
  height: 60%;
  background: linear-gradient(
    115.77deg,
    rgba(129, 169, 247, 0.8) 0%,
    #143f93 0.01%,
    #12b3ac 100%
  );
  transform: scale(0.5);
  transition: 0.4s all;

  flex-direction: column;
}
@media (max-width: 800px) {
  .ew-Modal-content {
    min-width: 90%;
  }
}
@media (max-width: 600px) {
  .ew-Modal-content {
    min-width: 100%;
  }
}

.ew-Modal.small-Modal .ew-Modal-content {
  height: 150px;
}

.ew-Modal.active .ew-Modal-content {
  transform: scale(1);
}

.ew-Modal-children {
  height: 80%;
  overflow: auto;
}

.ew-Modal.small-Modal .ew-Modal-children {
  height: 60%;
}

.ew-Modal-buttons {
  padding-top: 10px;
  width: 326px;
}

.ew-Modal-buttons.space-between {
  justify-content: space-between;
}

.ew-Modal-header {
  text-align: center;
  font-size: 24px;
}

.ew-Modal-email {
  text-align: center;
}

.ew-Modal-email-link {
  display: inline-block;
  transition: 0.3s;
  text-decoration: none;
  color: #a2f0ea;
}

.ew-Modal-email-link:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
