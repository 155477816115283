@import url("https://fonts.googleapis.com/css2?family=Exo:wght@500&family=Ubuntu:ital,wght@1,700&display=swap");

.eu-button-main-buy {
  margin-left: 60px;
}

.eu-Header-about {
  width: 10%;
}

.eu-about-link {
  margin-right: 20px;
  width: 120px;
  text-align: center;
}

.eu-about-link > span {
  text-align: center;
}

.eu-about-link > span:hover {
  font-size: 22px;
  transition: font-size, 0.05s;
}

.pseudo-element{
  width: 281px;
}

.pseudo-element__description{
  font-size: 16px;
  text-align: center;
  padding: 4px 10px;
}

@media (max-width: 768px){
  .pseudo-element{
    width: 220px;
  }
}

@media (max-width: 472px){
  .pseudo-element__description{
    font-size: 14px;
  }
  .pseudo-element__description{
    font-size: 15px;
    line-height: 1.1;
    width: 150px;
    padding-top: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 359px){
  .eu-about-link{
    width: 150px;
  }
  .eu-about-link > span:hover {
    font-size: 16px;
    transition: font-size, 0.05s;
  }
  .pseudo-element{
    width: 185px;
  }
  .pseudo-element__description{
    font-size: 14px;
    line-height: 1.1;
    width: 120px;
    padding-top: 5px;
    padding-left: 10px;
  }
}

@media (max-width: 320px){
  .eu-about-link{
    width: 135px;
  }
  .pseudo-element__description{
    font-size: 13px;
    line-height: 1.1;
    width: 120px;
    padding-left: 1px;
  }
}

@media (max-width: 290px){
  .eu-about-link{
    width: 105px;
  }
  .pseudo-element__description{
    font-size: 13px;
    line-height: 1.1;
    width: 120px;
    padding-left: 1px;
  }
}

@media (min-width: 1020px){
  .pseudo-element__description{
    display: none
  }
}