@import url("https://fonts.googleapis.com/css2?family=Exo:wght@500&display=swap");

body {
  color: #fff;
  font-family: "Exo";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
body:after {
  content : "";
  display: block;
  position: absolute;
  background: url('../../images/kofe_kruzhka_kniga_203336_1920x1080.jpeg') 0 0 / 100% auto no-repeat;
  width: 100%;
  height: calc(100% - 65px);
  opacity : 0.1;
  z-index: -1;
}

@media (max-width: 1920px) {
  body:after{
    width: 1920px;
    height: 870px;
  }
}

.eu-not-visible {
  display: none;
}

.eu-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eu-cursor-pointer {
  cursor: pointer;
}

.eu-Button-disabled {
  color: #a9caf7;
  border-color: #a9caf7 !important;
}

.ew-App__sticky-wrapper {
  position: relative;
  height: 65px;
}

.sticky .ew-Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 2px 10px 0 grey;
  transition: box-shadow 0.3s ease;
}
