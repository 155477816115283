@import url(https://fonts.googleapis.com/css2?family=Exo:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo:wght@500&family=Ubuntu:ital,wght@1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  color: #fff;
  font-family: "Exo";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
body:after {
  content : "";
  display: block;
  position: absolute;
  background: url(/static/media/kofe_kruzhka_kniga_203336_1920x1080.b8d4182a.jpeg) 0 0 / 100% auto no-repeat;
  width: 100%;
  height: calc(100% - 65px);
  opacity : 0.1;
  z-index: -1;
}

@media (max-width: 1920px) {
  body:after{
    width: 1920px;
    height: 870px;
  }
}

.eu-not-visible {
  display: none;
}

.eu-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eu-cursor-pointer {
  cursor: pointer;
}

.eu-Button-disabled {
  color: #a9caf7;
  border-color: #a9caf7 !important;
}

.ew-App__sticky-wrapper {
  position: relative;
  height: 65px;
}

.sticky .ew-Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 2px 10px 0 grey;
  transition: box-shadow 0.3s ease;
}

.eu-button-main-buy {
  margin-left: 60px;
}

.eu-Header-about {
  width: 10%;
}

.eu-about-link {
  margin-right: 20px;
  width: 120px;
  text-align: center;
}

.eu-about-link > span {
  text-align: center;
}

.eu-about-link > span:hover {
  font-size: 22px;
  transition: font-size, 0.05s;
}

.pseudo-element{
  width: 281px;
}

.pseudo-element__description{
  font-size: 16px;
  text-align: center;
  padding: 4px 10px;
}

@media (max-width: 768px){
  .pseudo-element{
    width: 220px;
  }
}

@media (max-width: 472px){
  .pseudo-element__description{
    font-size: 14px;
  }
  .pseudo-element__description{
    font-size: 15px;
    line-height: 1.1;
    width: 150px;
    padding-top: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 359px){
  .eu-about-link{
    width: 150px;
  }
  .eu-about-link > span:hover {
    font-size: 16px;
    transition: font-size, 0.05s;
  }
  .pseudo-element{
    width: 185px;
  }
  .pseudo-element__description{
    font-size: 14px;
    line-height: 1.1;
    width: 120px;
    padding-top: 5px;
    padding-left: 10px;
  }
}

@media (max-width: 320px){
  .eu-about-link{
    width: 135px;
  }
  .pseudo-element__description{
    font-size: 13px;
    line-height: 1.1;
    width: 120px;
    padding-left: 1px;
  }
}

@media (max-width: 290px){
  .eu-about-link{
    width: 105px;
  }
  .pseudo-element__description{
    font-size: 13px;
    line-height: 1.1;
    width: 120px;
    padding-left: 1px;
  }
}

@media (min-width: 1020px){
  .pseudo-element__description{
    display: none
  }
}
.eu-Button-container {
  width: 110px;
  height: 35px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  margin-top:10px;
  margin-bottom:10px;
}

.eu-Button-container:not(.eu-Button-disabled):hover {
  background-color: #4572d6;
  border-color: #ffffff;
}

.ew-Modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  z-index: 1;
}

.ew-Modal.active {
  opacity: 1;
  pointer-events: all;
}

.ew-Modal-content {
  padding: 30px;
  text-align: justify;
  text-justify: inter-word;
  width: 20%;
  min-width: 300px;
  height: 60%;
  background: linear-gradient(
    115.77deg,
    rgba(129, 169, 247, 0.8) 0%,
    #143f93 0.01%,
    #12b3ac 100%
  );
  transform: scale(0.5);
  transition: 0.4s all;

  flex-direction: column;
}
@media (max-width: 800px) {
  .ew-Modal-content {
    min-width: 90%;
  }
}
@media (max-width: 600px) {
  .ew-Modal-content {
    min-width: 100%;
  }
}

.ew-Modal.small-Modal .ew-Modal-content {
  height: 150px;
}

.ew-Modal.active .ew-Modal-content {
  transform: scale(1);
}

.ew-Modal-children {
  height: 80%;
  overflow: auto;
}

.ew-Modal.small-Modal .ew-Modal-children {
  height: 60%;
}

.ew-Modal-buttons {
  padding-top: 10px;
  width: 326px;
}

.ew-Modal-buttons.space-between {
  justify-content: space-between;
}

.ew-Modal-header {
  text-align: center;
  font-size: 24px;
}

.ew-Modal-email {
  text-align: center;
}

.ew-Modal-email-link {
  display: inline-block;
  transition: 0.3s;
  text-decoration: none;
  color: #a2f0ea;
}

.ew-Modal-email-link:hover {
  transform: scale(1.2);
}

.ew-Upload {
  flex-direction: column;
  min-height: 80%;
  min-width: 70%;
}

.ew-Upload-header {
  margin: 0;
}

.ew-Upload-chooseSection {
  margin-top: 56px;
  width: 340px;
  position: relative;
}

.ew-Upload-chooseSection.imageChosen {
  justify-content: space-between;
}

.ew-Upload-imageTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ew-Upload-chooseSection.imageChosen .ew-Upload-imageTitle {
  width: 161px;
}

.ew-Upload__input {
  position: relative;
  width: 350px;
}

.ew-Upload__input-label {
  position: absolute;
  left: 43%;
  top: 45px;
  font-size: 18px;
  background-color: transparent;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
}

.focused .ew-Upload__input-label {
  transform: translate(10%, -110%);
  font-size: 16px !important;
}

.ew-Upload__input-field {
  position: relative;
  width: 100%;
  height: 35px !important;
  color: #fff475;
  outline: 0;
  font-size: 20px;
  border-bottom: 1px solid #fff !important;
  transition: box-shadow 150ms ease-out;
  margin-top: 36px !important;
  background-color: transparent;
  border: none;
  text-align: center;
}

.ew-Upload-description {
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.ew-Upload-inputFile {
  width: 110px;
  height: 54px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  bottom: 2px;
  cursor: pointer !important;
}

.ew-Upload-chooseSection.imageChosen .ew-Upload-inputFile {
  left: 230px;
}

.ew-Upload-inputFile:hover + .eu-Button-container:not(.eu-Button-disabled) {
  background-color: #8bb0f7;
  border-color: #8bb0f7;
}

.crop_modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
.crop_content {
  height: 100%;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
}
.crop_actions {
  width: 70%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.ew-Content-pic {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: absolute;
  -webkit-user-select: none;
          user-select: none;
}

.ew-Content-pic.hovered {
  box-shadow: inset 0px 0px 3px 2px #7fa5ac;
}

.ew-Content-pic.chosen {
  box-shadow: inset 0px 0px 10px 3px #7fa5ac;
}

.bg_layer {
  position: absolute;
  z-index: 15;
  width: 1920px;
  height: 870px;
  background: #000;
  opacity: 0.4;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 0.4;
  transition: opacity 600ms;
}
.fade-exit {
  opacity: 0.4;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}
