.ew-Upload {
  flex-direction: column;
  min-height: 80%;
  min-width: 70%;
}

.ew-Upload-header {
  margin: 0;
}

.ew-Upload-chooseSection {
  margin-top: 56px;
  width: 340px;
  position: relative;
}

.ew-Upload-chooseSection.imageChosen {
  justify-content: space-between;
}

.ew-Upload-imageTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ew-Upload-chooseSection.imageChosen .ew-Upload-imageTitle {
  width: 161px;
}

.ew-Upload__input {
  position: relative;
  width: 350px;
}

.ew-Upload__input-label {
  position: absolute;
  left: 43%;
  top: 45px;
  font-size: 18px;
  background-color: transparent;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
}

.focused .ew-Upload__input-label {
  transform: translate(10%, -110%);
  font-size: 16px !important;
}

.ew-Upload__input-field {
  position: relative;
  width: 100%;
  height: 35px !important;
  color: #fff475;
  outline: 0;
  font-size: 20px;
  border-bottom: 1px solid #fff !important;
  transition: box-shadow 150ms ease-out;
  margin-top: 36px !important;
  background-color: transparent;
  border: none;
  text-align: center;
}

.ew-Upload-description {
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.ew-Upload-inputFile {
  width: 110px;
  height: 54px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  bottom: 2px;
  cursor: pointer !important;
}

.ew-Upload-chooseSection.imageChosen .ew-Upload-inputFile {
  left: 230px;
}

.ew-Upload-inputFile:hover + .eu-Button-container:not(.eu-Button-disabled) {
  background-color: #8bb0f7;
  border-color: #8bb0f7;
}

.crop_modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  align-items: center;
}
.crop_content {
  height: 100%;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
}
.crop_actions {
  width: 70%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
