.eu-Button-container {
  width: 110px;
  height: 35px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  margin-top:10px;
  margin-bottom:10px;
}

.eu-Button-container:not(.eu-Button-disabled):hover {
  background-color: #4572d6;
  border-color: #ffffff;
}
