.ew-Content-pic {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: absolute;
  user-select: none;
}

.ew-Content-pic.hovered {
  box-shadow: inset 0px 0px 3px 2px #7fa5ac;
}

.ew-Content-pic.chosen {
  box-shadow: inset 0px 0px 10px 3px #7fa5ac;
}

.bg_layer {
  position: absolute;
  z-index: 15;
  width: 1920px;
  height: 870px;
  background: #000;
  opacity: 0.4;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 0.4;
  transition: opacity 600ms;
}
.fade-exit {
  opacity: 0.4;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}